<template>
  <div data-app>
    <loading-spinner v-if="dataRetrieving" class="py-4 px-6"></loading-spinner>
    <div v-else-if="!paymentIntent" class="py-4 px-6">
      <h2>We couldn't retrieve your checkout information</h2>
    </div>
    <div v-else class="py-4 px-lg-6">
      <h2 v-if="paymentIntent.status === 'succeeded'">
        You've already completed this transaction.
      </h2>
      <h2 v-else-if="paymentIntent.status === 'canceled'">
        This transaction has been canceled.
      </h2>
      <div v-else-if="currentView === 'confirmation'">
        <slot></slot>
        <purchase-confirmation
          @handle-confirm="handleConfirm"
          :product="product"
          :loading="loading"
          :briefing="briefing"
          :selectedPaymentMethod="selectedPaymentMethod"
          :paymentIntent="paymentIntent"
        />
      </div>
      <stripe-checkout
        v-else-if="currentView === 'checkout'"
        @handle-submit="handleConfirm"
        @handle-cancel="handleCheckoutCancel"
        @handle-wrapper-mounted="getStripeElements"
        buttonText="Confirm Purchase"
        :paymentIntent="paymentIntent"
        :error="stripeElementsError"
        :loading="loading"
      ></stripe-checkout>
    </div>
    <select-payment-method-modal
      v-if="selectedPaymentMethod"
      :currentPaymentMethod="selectedPaymentMethod"
      :paymentMethods="paymentMethods"
      @handle-payment-method-select="handlePaymentMethodSelect"
    />
    <StatusDialog
      :show="showPaymentLoading"
      :type="'loading'"
      :heading="'Processing Payment...'"
      :description="'Your order is being processed, and you’ll receive confirmation shortly.'"
    />
    <StatusDialog
      :show="showPaymentSuccess"
      :type="'success'"
      :heading="'Payment Successful!'"
      :description="'Thank you for your purchase.'"
      @continue="handleContinue"
    />
    <StatusDialog
      :show="showPaymentFailure"
      :type="'fail'"
      :heading="'Payment Unsuccessful'"
      :description="'Unfortunately, we couldn’t process your payment. Please try using a different payment method.'"
      @cancel="handleCancel"
    />
  </div>
</template>

<script>
import StripeCheckout from "@/components/Payment/StripeCheckout"
import PurchaseConfirmation from "@/components/Payment/PurchaseConfirmation"
import SelectPaymentMethodModal from "@/components/Payment/SelectPaymentMethodModal"
import messagesMixin from "@/mixins/messagesMixin"
import stripeMixin from "@/mixins/stripeMixin"
import profile from "@/api/profile"
import company from "@/api/company"
import { STRIPE_PRODUCTS } from "@/utils/constants"
import StatusDialog from "@/components/Modals/StatusDialog"

export default {
  mixins: [messagesMixin, stripeMixin],
  components: {
    PurchaseConfirmation,
    SelectPaymentMethodModal,
    StripeCheckout,
    StatusDialog,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    dataRetrieving: {
      type: Boolean,
      required: true,
    },
    returnUrlPath: {
      type: String,
      required: true,
    },
    briefing: {
      type: Boolean,
      required: false,
    },
    jobId: {
      type: String,
      required: false,
    },
    candidateProfileId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      defaultErrorMessage: "We couldn't submit your payment request.",
      currentView: "confirmation",
      paymentIntent: null,
      stripeElementsError: null,
      paymentMethods: [],
      selectedPaymentMethod: null,
      showPaymentLoading: false,
      showPaymentSuccess: false,
      showPaymentFailure: false,
    }
  },
  async created() {
    await this.retrievePaymentIntent()
    await this.getPaymentMethods()
  },
  methods: {
    setLoading(loading) {
      this.$emit("set-loading", loading)
    },
    async getPaymentMethods() {
      const response = await this.$store.dispatch("api/getPaymentMethods")
      if (response.data.errors) {
        this.logDevError(response.data?.errors?.[0])
      } else {
        const profile = this.$store.state.userProfile
        const defaultPaymentMethodId = profile?.defaultPaymentMethod
        const paymentMethods = response.data
        if (paymentMethods.length < 1) {
          this.currentView = "checkout"
        } else {
          this.paymentMethods = paymentMethods ?? []
          const currentPaymentMethod = paymentMethods?.find(
            (paymentMethod) => paymentMethod.id === this.currentPaymentMethod
          )
          const defaultPaymentMethod = paymentMethods?.find(
            (paymentMethod) => paymentMethod.id === defaultPaymentMethodId
          )
          const selectedPaymentMethod =
            currentPaymentMethod ??
            defaultPaymentMethod ??
            paymentMethods[0] ??
            null
          this.selectedPaymentMethod = selectedPaymentMethod
        }
      }
    },
    async retrievePaymentIntent() {
      this.$emit("set-data-retrieving", true)
      const id = this.$router.currentRoute.query?.payment_intent
      if (id) {
        try {
          const { data } = await this.$store.dispatch("api/getPaymentIntent", {
            id,
          })
          if (!data) {
            throw new Error(this.defaultErrorMessage)
          }
          if (data.errors) {
            throw data.errors[0] ?? new Error(this.defaultErrorMessage)
          }
          this.paymentIntent = data
          this.$emit("set-data-retrieving", false)
        } catch (error) {
          this.logDevError(error)
          this.$emit("set-data-retrieving", false)
        }
      }
    },
    async getStripeElements() {
      await this.mountStripeElements(this.paymentIntent.client_secret)
    },
    async confirmStripeCardPayment(paymentMethod) {
      try {
        this.setLoading(true)
        this.showPaymentLoading = true
        const { client_secret } = this.paymentIntent
        const { error, paymentIntent } = await this.confirmCardPayment(
          client_secret,
          paymentMethod
        )
        if (error) {
          this.showPaymentLoading = false
          this.showPaymentFailure = true
          throw error
        }
        this.setLoading(false)
        // Send basic connection request
        let recipientProfileId = this.$router.currentRoute.params?.profileId
        let id
        let type
        let product_slug
        id = null
        type = "*"
        product_slug =
          this.product.name === "Job Posting"
            ? STRIPE_PRODUCTS.JOB_POSTING
            : this.product.name === "Unlock Applicant Resume"
            ? STRIPE_PRODUCTS.UNLOCK_APPLICANT_RESUME
            : this.product.name === "Send Job Post Match"
            ? STRIPE_PRODUCTS.SEND_JOB_POST_TO_MATCH
            : STRIPE_PRODUCTS.SKILLED_WORKER_PRIORITY_ACCESS
        if (product_slug == STRIPE_PRODUCTS.JOB_POSTING) {
          await this.$emit("createJob")
          return
        }
        const paymentData = {
          metadata: {
            ...(product_slug === STRIPE_PRODUCTS.SKILLED_WORKER_PRIORITY_ACCESS
              ? { profileId: this.$store.state.userProfile?.id }
              : {}),
            ...(product_slug === STRIPE_PRODUCTS.UNLOCK_APPLICANT_RESUME
              ? {
                  profileId: this.$store.state.userProfile?.id,
                  candidate_profile_id:
                    this.$router.currentRoute.params?.profileId,
                }
              : {}),
            ...(product_slug === STRIPE_PRODUCTS.JOB_POSTING
              ? { job_id: this.jobId }
              : {}),
            ...(product_slug === STRIPE_PRODUCTS.SEND_JOB_POST_TO_MATCH
              ? {
                  job_id: this.jobId,
                  candidate_profile_id: this.candidateProfileId,
                }
              : {}),
            profileId: this.$store.state.userProfile?.id,
            userId: this.$store.state.user.id,
            product_slug,
          },
          id: paymentIntent.id,
          available: true,
        }

        this.$store.dispatch("api/createPayment", paymentData)

        if (this.$store.state.userProfile.type == "jobSeeker") {
          await profile.requestProfileAccess(recipientProfileId, id, type)
        } else {
          await company.requestProfileAccess(recipientProfileId, id, type)
        }

        this.showPaymentLoading = false
        this.showPaymentSuccess = true
      } catch (error) {
        this.showPaymentLoading = false
        this.showPaymentFailure = true
        this.showErrorMessage(error, "We couldn't complete your payment.")
        this.setLoading(false)
      }
    },
    async confirmBriefingPayment(paymentMethod) {
      try {
        this.setLoading(true)
        const { client_secret } = this.paymentIntent
        const { error, paymentIntent } = await this.confirmCardPayment(
          client_secret,
          paymentMethod
        )
        if (error) {
          throw error
        }
        this.setLoading(false)
        // Send basic connection request
        let product_slug = STRIPE_PRODUCTS.CANDIDATE_JOB_BRIEFING
        const paymentData = {
          metadata: {
            profileId: this.$store.state.userProfile?.id,
            userId: this.$store.state.user.id,
            product_slug,
          },
          id: paymentIntent.id,
          available: true,
        }
        this.$store.dispatch("api/createPayment", paymentData)
      } catch (error) {
        this.showErrorMessage(error, "We couldn't complete your payment.")
        this.setLoading(false)
      }
    },
    async confirmStripePayment() {
      try {
        this.setLoading(true)
        this.showPaymentLoading = true

        if (this.briefing) {
          let product_slug = STRIPE_PRODUCTS.CANDIDATE_JOB_BRIEFING
          const paymentData = {
            metadata: {
              profileId: this.$store.state.userProfile?.id,
              userId: this.$store.state.user.id,
              product_slug,
            },
            id: this.paymentIntent.id,
            available: true,
          }
          this.$store.dispatch("api/createPayment", paymentData)
          await this.confirmPayment(
            `${process.env.VUE_APP_SITE_URL}${this.returnUrlPath}`
          )
          this.$emit("confirmCheckout")
        } else {
          const { error, paymentIntent } = await this.confirmPayment(
            `${process.env.VUE_APP_SITE_URL}${this.returnUrlPath}`
          )

          if (error) {
            this.showPaymentLoading = false
            this.showPaymentFailure = true
            throw error
          }

          let recipientProfileId = this.$router.currentRoute.params?.profileId
          let id
          let type
          let product_slug
          id = null
          type = "*"
          product_slug =
            this.product.name === "Job Posting"
              ? STRIPE_PRODUCTS.JOB_POSTING
              : this.product.name === "Unlock Applicant Resume"
              ? STRIPE_PRODUCTS.UNLOCK_APPLICANT_RESUME
              : this.product.name === "Send Job Post Match"
              ? STRIPE_PRODUCTS.SEND_JOB_POST_TO_MATCH
              : STRIPE_PRODUCTS.SKILLED_WORKER_PRIORITY_ACCESS
          if (product_slug == STRIPE_PRODUCTS.JOB_POSTING) {
            await this.$emit("createJob")
          }
          const paymentData = {
            metadata: {
              ...(product_slug ===
              STRIPE_PRODUCTS.SKILLED_WORKER_PRIORITY_ACCESS
                ? { profileId: this.$store.state.userProfile?.id }
                : {}),
              ...(product_slug === STRIPE_PRODUCTS.UNLOCK_APPLICANT_RESUME
                ? {
                    profileId: this.$store.state.userProfile?.id,
                    candidate_profile_id:
                      this.$router.currentRoute.params?.profileId,
                  }
                : {}),
              ...(product_slug === STRIPE_PRODUCTS.JOB_POSTING
                ? { job_id: this.jobId }
                : {}),
              ...(product_slug === STRIPE_PRODUCTS.SEND_JOB_POST_TO_MATCH
                ? {
                    job_id: this.jobId,
                    candidate_profile_id: this.candidateProfileId,
                  }
                : {}),
              userId: this.$store.state.user.id,
              product_slug,
            },
            id: paymentIntent.id,
            available: true,
          }

          this.$store.dispatch("api/createPayment", paymentData)
          this.$emit("confirmCheckout")

          if (
            recipientProfileId &&
            this.product.name !== "Unlock Applicant Resume"
          ) {
            if (this.$store.state.userProfile.type == "jobSeeker") {
              await profile.requestProfileAccess(recipientProfileId, id, type)
            } else {
              await company.requestProfileAccess(recipientProfileId, id, type)
            }
          }

          this.showPaymentLoading = false
          this.showPaymentSuccess = true
        }

        /*
        if (error) {
          this.logDevError(JSON.stringify(error))
          this.stripeElementsError = error.message
        }
        */
      } catch (error) {
        console.log("error", error)
        this.showPaymentLoading = false
        this.showPaymentFailure = true
        this.showErrorMessage(error, "We couldn't complete your payment.")
      } finally {
        this.setLoading(false)
      }
    },
    handlePaymentMethodSelect(paymentMethod) {
      this.selectedPaymentMethod = paymentMethod
    },
    handleCheckoutCancel() {
      this.currentView = "confirmation"
    },
    handleContinue() {
      this.showPaymentSuccess = false
      this.$router.push(`${this.returnUrlPath}`).then(() => {
        this.$router.go(0)
      })
    },
    handleCancel() {
      this.showPaymentFailure = false
    },
    async handleConfirm() {
      // We only set the view to 'checkout' if we've
      // mounted stripe elements.
      if (this.currentView === "checkout") {
        this.confirmStripePayment()
      } else if (this.selectedPaymentMethod) {
        if (this.briefing) {
          await this.confirmBriefingPayment(this.selectedPaymentMethod.id)
          this.$emit("confirmCheckout")
        } else {
          this.confirmStripeCardPayment(this.selectedPaymentMethod.id)
        }
      } else {
        if (this.briefing) {
          let test = await this.getStripeElements()
          console.log("test", test)
          //await this.confirmBriefingPayment(this.selectedPaymentMethod.id)
          //this.$emit("confirmCheckout")
        } else {
          this.$emit("goToCheckout")
          this.currentView = "checkout"
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
