<template>
  <div>
    <profile-section-header title="Professional Summary" />
    <div class="d-flex flex-wrap align-items-center mb-2">
      <ProfileSubSectionHeader title="Looking For" icon="looking-for-icon" />
      <capsule-list
        :items="profile.jobSeeker.positionSeeking || []"
        :highlighted="true"
      />
    </div>
    <!-- <capsule-list class="mt-4 mb-4" :items="experienceNames" /> -->
    <ProfileSubSectionHeader
      title="Trade Experience & Skills"
      icon="trade-experience-icon"
      :showEdit="profile._id == $store.state.userProfile._id ? true : false"
      @toggle-edit="toggleEdit"
      :isEditing="editSkills"
      @save="handleSave"
      @add-new="handleAddNew()"
      addNewLabel="Add New Skill"
    />
    <div class="row">
      <ul class="experience">
        <li
          v-for="(experience, index) in skillExperiences"
          :key="experience.category"
        >
          <label>{{ experience.category }}</label>
          <ExperienceSliderContainer
            :rating="experience.rating"
            @on-change="(rating) => updateRating(index, rating)"
            :isEditing="editSkills"
          />
          <div
            v-if="editSkills"
            class="delete-btn"
            @click="deleteExperience(experience.category)"
          >
            <PhTrash color="#525E77" :size="20" />
            Remove
          </div>
        </li>
      </ul>
    </div>
    <default-modal
      description="Please select the skills you want to add"
      title="Add New Skill"
      @onConfirm="handleModalConfirm()"
      id="add-experience-modal"
      :showCancel="true"
    >
      <template v-slot:input>
        <CustomMultiSelect
          :selectOptions="categoryOptions"
          :selectedList="handsOnExperienceCategories"
          selectLabel="Search or select category"
          @update-selectde="updateExperiences"
        ></CustomMultiSelect>
      </template>
    </default-modal>
  </div>
</template>

<script>
import ProfileSectionHeader from "../ProfileSectionHeader.vue"
import ProfileSubSectionHeader from "../ProfileSubSectionHeader.vue"
import ExperienceSliderContainer from "@/components/ExperienceSliderContainer"
import { PhTrash } from "phosphor-vue"
import options from "@/utils/options"
import CustomMultiSelect from "@/components/CustomMultiSelect"

export default {
  name: "candidate-profile-experience",
  components: {
    ProfileSectionHeader,
    ProfileSubSectionHeader,
    ExperienceSliderContainer,
    PhTrash,
    CustomMultiSelect,
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    experienceDescription: String,
    experiences: {
      type: Array,
      required: true,
      default: () => [],
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editSkills: false,
      skillExperiences: [],
      categoryOptions: options.categoryOptions,
      tempExperiences: [],
    }
  },
  created() {
    this.skillExperiences = JSON.parse(JSON.stringify(this.experiences))
  },
  methods: {
    updateRating(index, rating) {
      this.skillExperiences[index].rating = rating
    },
    deleteExperience(category) {
      this.skillExperiences = this.skillExperiences.filter(
        (experience) => experience.category !== category
      )
    },
    handleSave() {
      this.$emit("on-experiences-change", this.skillExperiences)
      this.editSkills = false
    },
    toggleEdit() {
      this.skillExperiences = JSON.parse(JSON.stringify(this.experiences))
      this.editSkills = !this.editSkills
    },
    selectExperience(option) {
      let experiences = [...this.tempExperiences]
      experiences.push({
        category: option,
        amount: "1-5YR",
      })
      this.tempExperiences = [...experiences]
    },
    removeExperience(option) {
      this.tempExperiences = this.tempExperiences.filter(
        (experience) => experience.category !== option
      )
    },
    updateExperiences(selected) {
      this.tempExperiences = selected
    },
    handleAddNew() {
      this.tempExperiences = JSON.parse(JSON.stringify(this.skillExperiences))
      this.$bvModal.show("add-experience-modal")
    },
    handleModalConfirm() {
      this.skillExperiences = JSON.parse(JSON.stringify(this.tempExperiences))
      this.$bvModal.hide("add-experience-modal")
      this.tempExperiences = []
    },
  },
  computed: {
    experienceNames() {
      const experienceNames = this.experiences.map(
        (experience) => experience.category
      )
      return experienceNames
    },
    handsOnExperienceCategories() {
      return this.tempExperiences.map((experience) => experience.category)
    },
  },
  watch: {
    experiences(newVal) {
      this.skillExperiences = [...newVal]
    },
  },
}
</script>

<style lang="scss" scoped>
.experience {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;

  li {
    display: flex;
    margin-bottom: 25px;
  }

  label {
    width: 35%;
    font-weight: 600;
    color: #3a4252;
  }

  .delete-btn {
    padding: 6px 12px 6px 8px;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #b0b9c9);
    font-size: 14px;
    margin-left: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
