<template>
  <div class="button-wrapper" :class="btnClass">
    <input
      type="button"
      :class="btnType"
      :value="labelText"
      @click="handleClick"
      :disabled="disabled"
    />
  </div>
</template>
<script>
export default {
  name: "default-button",
  props: {
    labelText: {
      type: String,
      default: "Submit",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    btnClass: {
      type: Array,
    },
    btnType: {
      type: Array,
    },
  },
  methods: {
    handleClick(evt) {
      this.$emit("click", evt)
    },
  },
}
</script>
<style>
input[type="button"] {
  background-color: var(--gfb-blue-brand-500, #0095e2);
  border: none;
  color: white;
  padding: 8px 32px;
  text-decoration: none;
  margin: 6px 2px;
  cursor: pointer;
  border-radius: 8px;
}

.right-end {
  margin-left: auto;
  margin-right: 0;
  float: right;
}

.back-btn {
  background-color: #ffffff !important;
  border: 2px solid var(--Blue-500, #4879c9) !important;
  color: var(--Blue-500, #4879c9) !important;
}

input[type="button"].cancel-edit-btn {
  border: 2px solid var(--Gray-300, #b0b9c9);
  background: #fff;
  color: var(--Gray-600, #525e77);
  padding: 6px 24px;
}

input[type="button"].cancel-btn {
  border: 2px solid var(--Gray-300, #b0b9c9);
  background: #fff;
  color: var(--Gray-600, #525e77);
  padding: 7px 24px;
}

input[type="button"].manage-btn {
  background-color: var(--gfb-blue-brand-500, #0095e2) !important;
}
</style>
