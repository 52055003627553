<template>
  <inner-layout-action-bar-wrapper data-app>
    <template v-slot:actions-left>
      <DefaultButton :labelText="'Go Back'" @click="$router.go(-1)" />
    </template>
    <template v-slot:actions-right>
      <DefaultButton
        v-if="!apply && !accepted && !rejected"
        :labelText="'Reject'"
        @click="handleEmployeeDecline"
      />
      <DefaultButton
        v-if="!apply && !accepted"
        :labelText="'Accept'"
        @click="handleAcceptMatch"
      />
      <DefaultButton
        v-if="!hasProfileViewAccess && !paymentIntent"
        :labelText="'View Full Profile'"
        @click="handleProfileViewAccess"
      />
      <DefaultButton
        v-if="paymentIntent"
        :labelText="'Confirm'"
        @click="handleConfirm"
        :disabled="dataRetrieving || !product"
      />
      <DefaultButton
        v-if="!paymentIntent && showSend"
        :labelText="'Send'"
        @click="handleSendJobPost"
      />
    </template>
    <template v-if="!paymentIntent">
      <div v-if="!apply">
        <profile
          :hide="false"
          :applicant="true"
          :hasProfileViewAccess="hasProfileViewAccess"
          @request-profile-access="handleProfileViewAccess"
        ></profile>
      </div>
      <div v-else>
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-lg-9 col-xl-7">
              <h1 class="mt-4 job-title">
                Congratulations, you are now connected
              </h1>
              <span
                >You can now connect on job details for {{ job.name }}
              </span>
              <h4 class="mt-4 mb-4">
                Job posted by: {{ $store.state.userProfile.name }}
              </h4>
              <div class="row justify-content-center">
                <div class="col-6">
                  <svg
                    class="mr-2"
                    width="20"
                    height="21"
                    viewBox="0 0 30 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M29.2133 22.0633L25.0179 17.8679C23.5196 16.3695 20.9724 16.9689 20.373 18.9168C19.9235 20.2653 18.4251 21.0145 17.0766 20.7148C14.0799 19.9656 10.0344 16.0699 9.28518 12.9233C8.83567 11.5747 9.73468 10.0764 11.0832 9.62694C13.0311 9.0276 13.6304 6.48039 12.1321 4.98204L7.93666 0.786637C6.73797 -0.262212 4.93994 -0.262212 3.89109 0.786637L1.04422 3.63351C-1.80266 6.63023 1.34389 14.5715 8.38616 21.6138C15.4284 28.6561 23.3697 31.9525 26.3664 28.9557L29.2133 26.1089C30.2622 24.9102 30.2622 23.1121 29.2133 22.0633Z"
                      fill="#003049"
                    />
                  </svg>
                  <span>{{ $store.state.userProfile.phoneNumber }}</span>
                </div>
                <div class="col-6">
                  <svg
                    class="mr-2"
                    width="25"
                    height="30"
                    viewBox="0 0 30 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M29.6063 0.172884C29.3188 -0.0308658 28.9413 -0.0583658 28.6288 0.105384L0.503806 14.7929C0.171306 14.9666 -0.0249444 15.3216 0.0025556 15.6954C0.0313056 16.0704 0.280056 16.3904 0.633806 16.5116L8.45256 19.1841L25.1038 4.94663L12.2188 20.4704L25.3226 24.9491C25.4201 24.9816 25.5226 24.9991 25.6251 24.9991C25.7951 24.9991 25.9638 24.9529 26.1126 24.8629C26.3501 24.7179 26.5113 24.4741 26.5526 24.2004L29.9901 1.07538C30.0413 0.725384 29.8938 0.377884 29.6063 0.172884Z"
                      fill="#003049"
                    />
                  </svg>
                  <span>{{ $store.state.userProfile.email }}</span>
                </div>
              </div>

              <h4 class="mt-4 mb-4">
                Interested Applicant: {{ interestedCompany.name }}
              </h4>
              <div class="row justify-content-center">
                <div class="col-6">
                  <svg
                    class="mr-2"
                    width="20"
                    height="21"
                    viewBox="0 0 30 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M29.2133 22.0633L25.0179 17.8679C23.5196 16.3695 20.9724 16.9689 20.373 18.9168C19.9235 20.2653 18.4251 21.0145 17.0766 20.7148C14.0799 19.9656 10.0344 16.0699 9.28518 12.9233C8.83567 11.5747 9.73468 10.0764 11.0832 9.62694C13.0311 9.0276 13.6304 6.48039 12.1321 4.98204L7.93666 0.786637C6.73797 -0.262212 4.93994 -0.262212 3.89109 0.786637L1.04422 3.63351C-1.80266 6.63023 1.34389 14.5715 8.38616 21.6138C15.4284 28.6561 23.3697 31.9525 26.3664 28.9557L29.2133 26.1089C30.2622 24.9102 30.2622 23.1121 29.2133 22.0633Z"
                      fill="#003049"
                    />
                  </svg>
                  <span>{{ interestedCompany.phoneNumber }}</span>
                </div>
                <div class="col-6">
                  <svg
                    class="mr-2"
                    width="25"
                    height="30"
                    viewBox="0 0 30 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M29.6063 0.172884C29.3188 -0.0308658 28.9413 -0.0583658 28.6288 0.105384L0.503806 14.7929C0.171306 14.9666 -0.0249444 15.3216 0.0025556 15.6954C0.0313056 16.0704 0.280056 16.3904 0.633806 16.5116L8.45256 19.1841L25.1038 4.94663L12.2188 20.4704L25.3226 24.9491C25.4201 24.9816 25.5226 24.9991 25.6251 24.9991C25.7951 24.9991 25.9638 24.9529 26.1126 24.8629C26.3501 24.7179 26.5113 24.4741 26.5526 24.2004L29.9901 1.07538C30.0413 0.725384 29.8938 0.377884 29.6063 0.172884Z"
                      fill="#003049"
                    />
                  </svg>
                  <span>{{ interestedCompany.email }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center mt-4"></div>
        </div>
      </div>
    </template>
    <product-purchase
      v-if="product"
      ref="productPurchase"
      :product="product"
      :jobId="jobId"
      :candidateProfileId="profileId"
      :loading="loading"
      :dataRetrieving="dataRetrieving"
      :returnUrlPath="returnUrlPath"
      @set-loading="setLoading"
      @set-data-retrieving="setDataRetrieving"
    >
    </product-purchase>
    <toast-message ref="toastMessage" />
    <StatusDialog
      :show="showLoading"
      :type="'loading'"
      :heading="'Loading'"
      :description="'Your Data is Loading. Please Wait...'"
    />
  </inner-layout-action-bar-wrapper>
</template>

<script>
import { nanoid } from "nanoid"
import company from "@/api/company"
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import ProductPurchase from "@/components/Payment/ProductPurchase"
import messagesMixin from "@/mixins/messagesMixin"
import paymentsMixin from "@/mixins/paymentsMixin"
import stripeMixin from "@/mixins/stripeMixin"
import { STRIPE_PRODUCTS } from "@/utils/constants"
import DefaultButton from "@/components/DefaultButton.vue"
import StatusDialog from "@/components/Modals/StatusDialog"

export default {
  mixins: [messagesMixin, paymentsMixin, stripeMixin],
  components: {
    InnerLayoutActionBarWrapper,
    ProductPurchase,
    DefaultButton,
    StatusDialog,
  },
  data() {
    const jobId = this.$router.currentRoute.params?.jobId
    const profileId = this.$router.currentRoute.params?.profileId
    const paymentIntent = this.$router.currentRoute.query?.payment_intent
    return {
      jobId,
      profileId,
      apply: false,
      project: {},
      interestedCompany: {},
      accepted: true,
      rejected: false,
      hasProfileViewAccess: false,
      productSlug: STRIPE_PRODUCTS.UNLOCK_APPLICANT_RESUME,
      sendJobSlug: STRIPE_PRODUCTS.SEND_JOB_POST_TO_MATCH,
      product: null,
      sendJobProduct: null,
      loading: false,
      dataRetrieving: false,
      returnUrlPath: `/jobs/${jobId}/matches/${profileId}`,
      backUrlPath: `/jobs/${jobId}/matches/${profileId}`,
      card: "",
      expiration: "",
      ccv: "",
      checkout: false,
      paymentIntent,
      showSend: true,
      showLoading: true,
    }
  },
  methods: {
    handleRemoveMatch() {
      console.log("TODO: Remove Match")
    },
    async handleProfileViewAccess() {
      this.loading = true

      const product = await this.getProduct(this.productSlug)
      this.product = product

      const paymentIntentData = {
        candidate_profile_id: this.profileId,
        product_slug: STRIPE_PRODUCTS.UNLOCK_APPLICANT_RESUME,
        return_url: this.returnUrlPath,
        idempotencyKey: nanoid(),
      }

      const response = await this.$store.dispatch(
        "api/createPaymentIntent",
        paymentIntentData
      )

      const defaultErrorMessage = "We couldn't submit your payment request."
      if (!response.data || response.data.errors) {
        throw new Error(response.data?.errors ?? defaultErrorMessage)
      }
      const paymentIntent = response.data
      if (paymentIntent) {
        this.$router.push(
          `${this.returnUrlPath}?payment_intent=${paymentIntent.id}`
        )
        window.location.reload()
      } else {
        throw new Error(defaultErrorMessage)
      }
    },
    async handleSendJobPost() {
      this.loading = true

      const product = await this.getProduct(this.sendJobSlug)
      this.sendJobProduct = product

      const paymentIntentData = {
        job_id: this.jobId,
        candidate_profile_id: this.profileId,
        product_slug: STRIPE_PRODUCTS.SEND_JOB_POST_TO_MATCH,
        return_url: this.returnUrlPath,
        idempotencyKey: nanoid(),
      }

      const response = await this.$store.dispatch(
        "api/createPaymentIntent",
        paymentIntentData
      )

      const defaultErrorMessage = "We couldn't submit your payment request."
      if (!response.data || response.data.errors) {
        throw new Error(response.data?.errors ?? defaultErrorMessage)
      }
      const paymentIntent = response.data
      if (paymentIntent) {
        this.$router.push(
          `${this.returnUrlPath}?payment_intent=${paymentIntent.id}`
        )
        window.location.reload()
      } else {
        throw new Error(defaultErrorMessage)
      }
    },
    async handleConfirm() {
      this.$refs.productPurchase.handleConfirm()
    },
    setLoading(loading) {
      this.loading = loading
    },
    setDataRetrieving(isRetrieving) {
      this.dataRetrieving = isRetrieving
    },
    goToCheckout() {
      console.log("CHECK")
      this.checkout = !this.checkout
    },
    async handleAcceptMatch() {
      let profileId = this.$router.currentRoute.params.profileId
      let jobId = this.jobId

      await company.grantProfileAccess(profileId, jobId, "job-application")
      await this.$store.dispatch("api/getUserProfile", {
        userId: this.$store.state.user._id,
      })

      await this.getCompanyProfile()
      this.apply = true
      this.accepted = true

      let payload = {
        companyId: this.$store.state.userProfile._id,
        jobseekerId: profileId,
        jobId: jobId,
        userType: this.$store.state.userProfile.type,
        activityType: "accept-job-application",
      }
      this.$store.dispatch("api/createLog", payload)

      await this.getJobById()
      this.$forceUpdate()
    },
    async getCompanyProfile() {
      try {
        const list = await this.$store.dispatch("api/getProfile", {
          profileId: this.$router.currentRoute.params.profileId,
        })
        this.interestedCompany = list.data
      } catch (error) {
        this.$toast.error(error || "Something went wrong.")
        console.log(error)
      }
    },
    async handleEmployeeDecline() {
      console.log("handleEmployeeDecline")
      let id = this.jobId
      let type = "job-application"

      try {
        await company.rejectProfileAccess(this.profileId, id, type)
        await this.$store.dispatch("api/getUserProfile", {
          userId: this.$store.state.user._id,
        })
        await this.getCompanyProfile()
        this.$refs.toastMessage.showToast(
          "Application Declined successfully!",
          "success"
        )
        this.$router.push("/employees/jobs")
      } catch (error) {
        this.$refs.toastMessage.showToast("Something went wrong!!", "error")
      }
    },
    getMatches() {
      this.accepted = false
      let accepted = this.$store.state.userProfile.profilesGranted
      let rejected = this.$store.state.userProfile.profilesRejected

      let isAccepted = accepted.find((r) => {
        return (
          r.type == "job-application" &&
          r.job == this.jobId &&
          r.profile == this.profileId
        )
      })

      this.accepted = !isAccepted ? false : true

      let isRejected = rejected.find((r) => {
        return (
          r.type == "job-application" &&
          r.job == this.jobId &&
          r.profile == this.profileId
        )
      })

      this.rejected = !isRejected ? false : true
    },
    async getJobById() {
      const profileId = this.$store.state.userProfile?._id
      if (profileId) {
        try {
          const data = await this.$store.dispatch("jobs/dispatchFetchJobById", {
            id: this.$route.params.jobId,
          })
          if (data.error) {
            this.$router.push("/dashboard")
            return
          }
          this.job = data
          this.getMatches()
        } catch (error) {
          console.log("error", error)
          this.logDevError(error)
        }
      }
    },
    goToCompanyProfile(id) {
      this.$router.push("/profile/" + id)
    },
    async getProfileAccessDetails() {
      const userId = this.$store.state.user?._id
      const { data } = await this.$store.dispatch("api/getCompanyUserProfile", {
        userId,
      })
      await this.$store.commit("UPDATE_USER_PROFILE", data)

      let profileId = this.profileId,
        unlockedApplicants = data.provider.unlockedApplicants || []

      let isUnlockedProfile = unlockedApplicants.find(
        (item) => item.profile === profileId
      )

      this.hasProfileViewAccess = !isUnlockedProfile ? false : true

      this.paymentIntent =
        this.$router.currentRoute.query?.payment_intent ?? null

      let sentJobPosts = data?.provider.sentJobPosts ?? []

      let alreadySendPost = sentJobPosts.findIndex((post) => {
        return post.profile == this.profileId && post.job == this.jobId
      })

      this.showSend = alreadySendPost > -1 ? false : true
      this.showLoading = false
    },
  },
  mounted() {
    this.getJobById()

    setTimeout(() => {
      this.getProfileAccessDetails()
    }, 3000)
  },
  async created() {
    const product = await this.getProduct(this.productSlug)
    this.product = product
    this.dataRetrieving = false
  },
}
</script>

<style scoped></style>
