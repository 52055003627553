<template>
  <b-modal v-bind="$attrs" v-on="$listeners">
    <!-- pass through scoped slots -->
    <template
      v-for="(_, scopedSlotName) in $scopedSlots"
      v-slot:[scopedSlotName]="slotData"
    >
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>

    <!-- pass through normal slots -->
    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>

    <template #modal-header="{ close }">
      <slot name="modal-header" />
      <div class="w-100 d-flex justify-content-between align-items-center">
        <h5 class="font-22-700 m-0">{{ $attrs.title }}</h5>
        <button type="button" class="close" @click="close()" aria-label="Close">
          <icon icon="close" aria-hidden="true" />
        </button>
      </div>
    </template>

    <template #modal-footer="{ ok, cancel }">
      <div class="w-100 d-flex align-items-center justify-content-end">
        <default-button
          :btnType="['cancel-btn']"
          :labelText="$attrs['cancel-title'] || 'Cancel'"
          @click="cancel()"
        />
        <default-button :labelText="$attrs['ok-title'] || 'OK'" @click="ok()" />
      </div>
    </template>
  </b-modal>
</template>
<script>
export default {
  name: "base-modal",
}
</script>
<style>
.modal-content {
  border-radius: 22px;
}
.modal-footer {
  box-shadow: 0px 4px 37px rgba(0, 0, 0, 0.17);
}
.modal-body {
  input[type="button"].cancel-btn {
    border: 2px solid var(--Gray-300, #b0b9c9);
    background: #fff;
    color: var(--Gray-600, #525e77);
    padding: 7px 24px;
  }
}
</style>
