<template>
  <form class="my-4">
    <text-input
      label="Project name *"
      :hasError="errors.includes('name')"
      :errMsg="'This field is required'"
      v-model="project.name"
    >
    </text-input>

    <text-input
      label="Type of Project *"
      :hasError="errors.includes('type')"
      :errMsg="'This field is required'"
      v-model="project.type"
    >
    </text-input>

    <h3 class="mt-4">Description<sup class="required">*</sup></h3>
    <b-form-textarea
      style="margin-bottom: 1.5rem"
      v-model="project.description"
      :class="{ 'input-error': errors.includes('description') }"
      placeholder="Brief description of the project*"
      rows="6"
    ></b-form-textarea>
    <span class="font-14-500 invalid" v-if="errors.includes('description')"
      >This field is required</span
    >

    <text-input
      label="Project location *"
      :hasError="invalidZip || errors.includes('zip')"
      :errMsg="'Please enter a valid ZIP code'"
      v-model="project.zip"
      @keyup="validateZip(project.zip)"
    >
    </text-input>

    <text-input
      label="Estimated Project Start Date *"
      :hasError="errors.includes('startDate')"
      :errMsg="'This field is required'"
      type="date"
      v-model="project.startDate"
    >
    </text-input>

    <h3 class="mt-4">
      Please select all contractor trade categories you would like to invite to
      view this project<sup class="required">*</sup>
    </h3>
    <div
      :class="{ 'input-error': errors.includes('contractorTradeCategories') }"
    >
      <CustomMultiSelect
        :selectOptions="categoryOptions"
        :selectedList="project.contractorTradeCategories"
        selectLabel="Select Trade Categories"
        @update-selected="updateCategories"
      ></CustomMultiSelect>
      <span
        class="font-14-500 invalid"
        v-if="errors.includes('contractorTradeCategories')"
        >This field is required</span
      >
    </div>

    <!-- <h3
      :class="{
        'mt-4': true,
        'pill-error': errors.includes('inviteVendorsToView'),
      }"
    >
      Would you like to invite Vendors to view this project?<sup
        class="required"
        >*</sup
      >
    </h3> -->
    <b-form-group
      label="Would you like to invite Vendors to view this project?"
      label-cols-sm="6"
      label-cols-lg="6"
      content-cols-sm
      content-cols-lg="6"
      :class="[
        'gfb-radio-container',
        'invite-vendor',
        'required',
        { 'pill-error': errors.includes('inviteVendorsToView') },
      ]"
      required
    >
      <b-form-radio-group
        v-model="project.inviteVendorsToView"
        :options="trueFalse"
      ></b-form-radio-group>
      <span
        class="font-14-500 invalid"
        v-if="errors.includes('inviteVendorsToView')"
        >This field is required</span
      >
    </b-form-group>

    <multiselect
      v-model="project.vendorTradeCategories"
      placeholder="Search or add a category"
      :options="categoryOptions"
      :multiple="true"
      :taggable="true"
      :close-on-select="false"
      v-if="project.inviteVendorsToView === 'true'"
    >
    </multiselect>

    <h3 class="mt-4">Additional information (Optional)</h3>
    <b-form-textarea
      v-model="project.addedDetail"
      placeholder="Please provide additional project information, budgets, or specific requirements for this project*"
      rows="6"
    ></b-form-textarea>
  </form>
</template>

<script>
import options from "@/utils/options"
import CustomMultiSelect from "@/components/CustomMultiSelect"

export default {
  props: {
    project: Object,
    errors: Array,
  },
  components: {
    CustomMultiSelect,
  },
  data() {
    return {
      categoryOptions: options.categoryOptions,
      trueFalse: options.trueFalse,
      invalidZip: false,
    }
  },
  methods: {
    removeCategory(option) {
      this.project.contractorTradeCategories =
        this.project.contractorTradeCategories.filter(
          (category) => category !== option
        )
    },
    addCategory(option) {
      this.project.contractorTradeCategories.push(option)
    },
    updateCategories(selected) {
      this.project.contractorTradeCategories = selected
    },
    validateZip(zip) {
      const invalidZip = !zip || !zip.match(/^[0-9]{5}(?:-[0-9]{4})?$/)
      this.invalidZip = invalidZip
    },
  },
}
</script>

<style scoped>
.invalid,
sup.required {
  color: var(--Red-500, #e24646);
}

.form-group {
  margin-bottom: 0;
}

h3 {
  font-weight: normal;
  font-size: 1.0625rem;
}
</style>
