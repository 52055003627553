<template>
  <inner-layout-action-bar-wrapper>
    <template v-slot:actions-left
      ><span class="mr-1">
        Recruit the skilled workers you need directly to your inbox
      </span></template
    >
    <template v-slot:actions-right>
      <div class="d-flex align-items-center">
        <label for="actively-hiring" class="actively-hiring-label mb-0 mr-2"
          >Send me Skilled Workers</label
        >
        <b-form-checkbox
          switch
          id="actively-hiring"
          v-model="activelyHiring"
          @change="updatePublicStatus"
          class="actively-hiring-switch"
        ></b-form-checkbox>
      </div>
    </template>
    <div class="mx-auto w-75" v-if="!activelyHiring">
      <h2>Ready to connect with a Skilled Worker?</h2>
      <p class="mb-5">
        Its no secret, many companies need skilled workers and they are
        incredibly hard to find. GoFindBuild is a building a network of
        qualified skilled workers and allowing priority access to companies that
        need them the most. Our recruiting process allows immediate priority and
        exclusive access to skilled workers that are actively looking for new
        opportunities and match the skills you need. With recruiting turned on,
        you will have the opportunity to invest in full access to skilled worker
        profiles to connect and take the next steps to building a stronger
        workforce.
      </p>
      <b-row>
        <b-col md="4">
          <b-row
            ><span class="mr-2 orange-clr font-18-900">1.</span>
            <h3>Turn On Recruiting</h3>
          </b-row>
          <p>
            Enable recruiting when you are seeking skilled job seekers to grow
            your company and we will notify you when they sign up
          </p>
        </b-col>
        <b-col md="4">
          <b-row
            ><span class="mr-2 orange-clr font-18-900">2.</span>
            <h3>Receive Skilled Worker Matches</h3></b-row
          >
          <p>
            We will automatically recruit the worker skills that match the trade
            categories from your company profile so you can review worker
            profiles as soon as we recruit them.
          </p>
        </b-col>
        <b-col md="4">
          <b-row
            ><span class="mr-2 orange-clr font-18-900">3.</span>
            <h3>Get Priority Access</h3>
          </b-row>
          <p>
            If you find a great match you can invest $449 to join only a select
            few companies to compete for your next skilled worker
          </p>
        </b-col>
        <b-col md="4">
          <b-row
            ><span class="mr-2 orange-clr font-18-900">4.</span>
            <h3>Submit Job Briefing</h3>
          </b-row>
          <p>
            Send the matching skilled job seeker a summary of the position and
            salary range you would offer to join your team.
          </p>
        </b-col>
        <b-col md="4">
          <b-row
            ><span class="mr-2 orange-clr font-18-900">5.</span>
            <h3>Connect + Hire</h3>
          </b-row>
          <p>
            If the skilled worker accepts your job briefing , connect directly
            and schedule an interview to finalize the hiring process - good
            luck!
          </p>
        </b-col>
      </b-row>
    </div>
    <div class="" v-else>
      <b-row class="mb-5">
        <b-col sm md="7">
          <CustomMultiSelect
            :selectOptions="categoryOptions"
            :selectedList="categories"
            selectLabel="Select Trade Categories"
            @update-selected="updateCategories"
          ></CustomMultiSelect>
        </b-col>
        <!--b-col>
          Sort by:
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-secondary">All</button>
            <button type="button" class="btn btn-secondary">
              Priority Access
            </button>
            <button type="button" class="btn btn-secondary">
              Briefing Sent
            </button>
          </div>
        </b-col-->
      </b-row>
      <div class="row" v-if="skilledWorkers.length">
        <div
          v-for="seeker in skilledWorkers"
          :key="seeker._id"
          class="col-lg-4"
        >
          <employee-card :seeker="seeker" />
        </div>
      </div>
      <div class="row" v-else-if="skilledWorkers.length == 0">
        <div
          v-if="loadingData"
          class="text-center align-items-center"
          style="margin: auto"
        >
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <div v-else>
          <h2 class="ml-3">No results. Check back next time!</h2>
        </div>
      </div>
    </div>
  </inner-layout-action-bar-wrapper>
</template>

<script>
import options from "@/utils/options"
import EmployeeCard from "@/components/Cards/EmployeeCard"
import InnerLayoutActionBarWrapper from "@/layout/InnerLayoutActionBarWrapper"
import CustomMultiSelect from "@/components/CustomMultiSelect"

export default {
  components: {
    InnerLayoutActionBarWrapper,
    EmployeeCard,
    CustomMultiSelect,
  },
  data() {
    return {
      categoryOptions: options.categoryOptions,
      activelyHiring: this.$store.state.userProfile.public,
      categories: this.$store.state.userProfile.selfPerformWorkCategories,
      skilledWorkers: [],
      loadingData: false,
    }
  },
  methods: {
    removeCategory(option) {
      this.categories = this.categories.filter(
        (category) => category !== option
      )
      this.fetchSkilledWorkers()
    },
    addCategory(option) {
      this.categories.push(option)
      this.fetchSkilledWorkers()
    },
    updateCategories(selected) {
      this.categories = selected
    },
    async updatePublicStatus() {
      try {
        /*
        if (this.activelyHiring) {
          this.activelyHiring = false;
        } else {
          this.activelyHiring = true;
        }
        */
        console.log("HERE", this.activelyHiring)
        const profile = this.$store.state.userProfile
        const updatedProfile = {
          ...profile,
          public: this.activelyHiring,
        }
        await this.$store.dispatch("api/updateCompanyProfile", {
          profileId: updatedProfile.id,
          data: updatedProfile,
        })
        window.location.reload()
      } catch (e) {
        console.error(e)
      }
    },
    async fetchSkilledWorkers() {
      this.loadingData = true
      this.skilledWorkers = []
      const date = new Date()
      const daysAgo = new Date(date.getTime())
      daysAgo.setDate(date.getDate() - 3)
      try {
        const list = await this.$store.dispatch("api/searchUsersByParams", {
          filters: {
            zip: this.$store.state.userProfile.zip,
            radius: 50,
          },
          mongoFilters: {
            //"jobSeeker.hasHandsOnExperience": true,
            ...(this.categories.length
              ? {
                  $or: [
                    {
                      "jobSeeker.handsOnExperiences.category": this.categories,
                    },
                    { "jobSeeker.formalTrainingCategories": this.categories },
                  ],
                }
              : {}),
            type: "jobSeeker",
            public: true,
            // 'jobSeeker.lastPriorityAccess': { $gt: daysAgo.toISOString()}
            $and: [
              {
                $or: [
                  {
                    "jobSeeker.lastPriorityAccess": {
                      $gt: daysAgo.toISOString(),
                    },
                  },
                  { "jobSeeker.lastPriorityAccess": { $exists: false } },
                ],
              },
              {
                $or: [
                  { "jobSeeker.hasFormalTraining": true },
                  { "jobSeeker.hasHandsOnExperience": true },
                ],
              },
            ],
          },
        })
        console.log(list)
        this.skilledWorkers = list.data
        this.loadingData = false
      } catch (error) {
        this.loadingData = false
        this.$toast.error(error || "Something went wrong.")
        console.log(error)
      }
    },
  },
  mounted() {
    this.fetchSkilledWorkers()
  },
}
</script>

<style global lang="scss">
.actively-hiring-switch {
  margin-right: -1.5rem;
  & .custom-control-input:checked ~ .custom-control-label::before {
    background-color: var(--green);
    border-color: var(--green);
  }
}
</style>

<style scoped lang="scss">
.actively-hiring-label {
  white-space: nowrap;
}
</style>
