<template>
  <div class="container-fluid">
    <div class="row">
      <p class="info-header">
        This is where you’ll see all of the jobs you’ve saved. These could be
        jobs that you’ve found via search or jobs that have been sent to you.
      </p>
      <table
        class="table table-striped table-hover jobs-table jobs-list"
        v-if="jobs.length"
      >
        <thead>
          <tr>
            <th v-for="column in columns" :key="column">{{ column }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in lists" :key="index">
            <td>
              <signed-image
                v-if="
                  item.company[0].profilePhoto &&
                  typeof item.company[0].profilePhoto === 'object'
                "
                :file="item.company[0].profilePhoto"
                :alt="item.company[0].name"
              />
              <img v-else src="/img/icons/default-company.svg" alt="" />
              {{ item.profile[0].name }}
            </td>
            <td>{{ item.name }}</td>
            <td class="status">{{ item.city }}, {{ item.state }}</td>
            <td>{{ item.compensation }}</td>
            <td class="action-data">
              <ActionButtonsDropdown
                :actions="actionsArray"
                :btnText="'Manage Post'"
                :id="item._id"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <!--- mobile view --->
      <div class="jobs-list-mobile mobile-cards-wrapper" v-if="jobs.length">
        <div
          v-for="(item, index) in lists"
          :key="index"
          class="activity-wrapper mobile-card"
        >
          <div class="img">
            <img src="/img/icons/default-company.svg" alt="" />
          </div>
          <div class="company-name">
            {{ item.profile[0].name }}
          </div>
          <div class="role-name">
            {{ item.name }}
          </div>
          <div class="dates-wrapper">
            <div class="date">
              <span class="date-header">Location</span>
              {{ item.city }}, {{ item.state }}
            </div>
            <div class="date">
              <span class="date-header">Compensation</span>
              {{ item.compensation }}
            </div>
          </div>
          <div class="btns-wrapper">
            <div class="btn view-btn">
              <a @click="$router.push(`/jobs/${item._id}`)">View Job</a>
            </div>
            <div class="btn">
              <a @click="handleSaveJob(item._id)">Remove From List</a>
            </div>
          </div>
        </div>
      </div>
      <!--- mobile view end --->

      <div class="empty-data" v-if="!jobs.length">
        <p>No data available.</p>
      </div>
      <div class="row pagination" v-if="jobs.length">
        <b-pagination
          v-model="currentPage"
          :total-rows="jobs.length"
          :per-page="limit"
          @click="goToPage"
        ></b-pagination>
      </div>
    </div>
    <toast-message ref="toastMessage" />
  </div>
</template>

<script>
import ActionButtonsDropdown from "@/components/ActionButtonsDropdown"

export default {
  name: "SavedJobs",
  components: {
    ActionButtonsDropdown,
  },
  data() {
    return {
      jobs: [],
      columns: ["Company", "Role", "Location", "Compensation", "Actions"],
      currentPage: 1,
      limit: 20,
      actionsArray: [
        { name: "Remove from List", function: this.handleSaveJob },
        { name: "View Job", function: this.goToJob },
      ],
    }
  },
  methods: {
    async getSavedJobs() {
      let jobs = []
      const profileId = this.$store.state.userProfile?._id
      if (profileId) {
        try {
          for (let i of this.$store.state.userProfile.savedJobs) {
            const data = await this.$store.dispatch(
              "jobs/dispatchFetchJobById",
              { id: i }
            )
            if (data) {
              jobs.push(data)
            }
          }
          this.jobs = jobs
        } catch (error) {
          this.logDevError(error)
        }
      }
    },
    goToJob(id) {
      this.$router.push("/jobs/" + id)
    },
    goToPage(page) {
      this.currentPage = page
    },
    async handleSaveJob(id) {
      try {
        let userProfile = JSON.parse(
          JSON.stringify(this.$store.state.userProfile)
        )
        if (userProfile.savedJobs) {
          if (userProfile.savedJobs.includes(id)) {
            let index = userProfile.savedJobs.indexOf(id)
            userProfile.savedJobs.splice(index, 1)
          } else {
            userProfile.savedJobs.push(id)
          }
        } else {
          userProfile.savedJobs = [id]
        }
        let { data } = await this.$store.dispatch("api/updateProfile", {
          profileId: userProfile._id,
          data: { ...userProfile },
        })

        await this.$store.commit("UPDATE_USER_PROFILE", data)

        this.getSavedJobs()
      } catch (error) {
        console.log("error", error)
        this.logDevError(error)
      }
    },
  },
  mounted() {
    this.getSavedJobs()
  },
  computed: {
    lists() {
      const items = this.jobs
      // Return just page of items needed
      return items.slice(
        (this.currentPage - 1) * this.limit,
        this.currentPage * this.limit
      )
    },
  },
}
</script>

<style scoped lang="scss">
.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;

  .row {
    margin-left: 0;
    margin-right: 0;
    p.info-header {
      color: var(--Gray-800, #3a4252);
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.empty-data {
  width: 100%;
  text-align: center;
  background: #ffff;
  padding: 24px;
  border: 1px solid var(--Gray-300, #b0b9c9) !important;
  border-radius: 8px;

  p {
    font-size: 18px;
    margin: 0;
    color: var(--Gray-800, #3a4252);
  }
}

.jobs-list-mobile {
  display: none;
  width: 100%;
}

@media (max-width: 992px) {
  .jobs-list {
    display: none;
  }

  .jobs-list-mobile {
    display: flex;
  }
}
</style>
