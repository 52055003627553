<template>
  <div>
    <b-form-group>
      <v-select
        v-model="selected"
        :items="filteredOptions"
        :menu-props="{ maxHeight: '400' }"
        :label="selectLabel"
        chips
        @change="onChange"
        multiple
        outlined
      >
        <template v-slot:prepend-item>
          <v-list-item class="search-input-wrapper">
            <v-list-item-content>
              <span class="empty-info" v-if="!selected.length"
                >Select one or more items</span
              >
              <div class="count-info" v-else>
                <div class="count-display">
                  <span class="count">{{ selected.length }}</span
                  >Selected
                </div>
                <a class="clear-btn" @click="clearSelection">Clear All</a>
              </div>
              <v-text-field
                v-model="search"
                label="Search"
                @keydown.enter="handleTag"
                outlined
              ></v-text-field>
            </v-list-item-content>
          </v-list-item>
        </template>
        <!-- <template v-slot:selection="{ item, index }">
          <span v-if="index <= limit"> {{ item }} </span>
          <span v-if="index === limit && selected.length > limit + 1">
            ...
          </span>
          <span
            v-if="index === limit && selected.length > limit + 1"
            class="grey--text text-caption"
          >
            +{{ selected.length - (limit + 1) }}
          </span>
        </template> -->
      </v-select>
    </b-form-group>
  </div>
</template>
<script>
export default {
  name: "custom-multi-select",
  props: {
    selectOptions: {
      type: Array,
    },
    selectLabel: {
      type: String,
      default: "Search or select experience",
    },
    selectedList: {
      type: Array,
    },
    tagFlag: {
      type: Boolean,
      default: false,
    },
    showNoOptionsFlag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: [...new Set(this.selectedList)] ?? [],
      options: [...this.selectOptions] ?? [],
      search: "",
      limit: 0,
    }
  },
  computed: {
    filteredOptions() {
      if (!this.search) {
        return this.selectOptions
      }

      return this.selectOptions.filter((option) =>
        option.toLowerCase().includes(this.search.toLowerCase())
      )
    },
  },
  watch: {
    selectedList(newval) {
      this.selected = [...new Set(newval)]
    },
    selectOptions(newval) {
      this.options = [...newval]
    },
  },
  methods: {
    handleTag() {
      if (this.tagFlag && this.search.trim() !== "") {
        if (!this.selectedList.includes(this.search)) {
          this.selectedList.push(this.search)
          if (!this.selectOptions.includes(this.search)) {
            this.selectOptions.push(this.search)
          }
        }
        this.search = ""
      }
    },
    onChange(value) {
      this.$emit("update-selected", value)
    },
    clearSelection() {
      this.selected = []
      this.$emit("update-selected", [])
    },
  },
}
</script>
<style>
.v-menu__content {
  top: 100% !important;
  left: 0 !important;
  position: absolute !important;

  .v-list-item {
    gap: 8px;
    color: var(--Gray-900, #343946) !important;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 5px;

    &:focus {
      background: transparent;
    }

    &:hover {
      background: var(--Gray-100, #eceef2) !important;

      .v-simple-checkbox {
        .v-icon {
          color: #0095e2;
        }
      }
    }

    &.v-list-item--active {
      background: var(--gfb-blue-brand-50, #c8ecff);
      color: var(--gfb-blue-brand-500, #0095e2) !important;

      &:hover {
        background: transparent !important;
      }

      .v-simple-checkbox {
        .v-icon {
          color: #0095e2;
        }
      }
    }

    &.search-input-wrapper {
      &:hover {
        background: transparent !important;
      }

      .v-input:not(.v-input--is-focused) {
        fieldset {
          border: 2px solid var(--Gray-300, #b0b9c9) !important;
        }

        label {
          color: #525e77 !important;
        }
      }

      legend {
        margin-left: 0;
      }
    }
  }
}

.v-input--is-focused {
  .v-menu {
    display: block;
  }
}

.v-select {
  legend {
    font-size: 7px !important;
  }

  &.state-select {
    label {
      top: 17px !important;
    }
  }

  label {
    color: var(--Gray-600, #525e77) !important;
    top: 13px !important;

    &.v-label--active {
      transform-origin: 35px 12px;
    }
  }

  .v-select .v-select__selections {
    color: #343946;
    font-size: 16px;
  }

  fieldset {
    border-radius: 8px !important;
    padding: 8px 16px;
    align-items: center;
    border: 2px solid var(--Gray-300, #b0b9c9) !important;
    background: var(--White, #fff);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
    height: auto;
    margin-bottom: 5px;
  }

  .v-select__slot {
    padding: 8px 0;
    .v-input__append-inner {
      position: absolute;
      top: -5px;
      right: 0;
    }
  }

  &.v-input--is-focused {
    fieldset {
      border: 2px solid var(--Gray-300, #0095e2) !important;
    }

    .v-input__slot:hover {
      fieldset {
        border: 2px solid var(--Gray-300, #0095e2) !important;
      }
    }

    label {
      color: #0095e2 !important;
    }

    .v-select__slot {
      position: relative;
      display: inline-block;

      .v-input__append-inner {
        i {
          color: #0095e2;
        }
      }
    }
  }

  .v-select__selections {
    color: #343946;

    .v-chip {
      font-size: 16px;
      color: var(--Gray-900, #343946);
    }
  }

  .v-input__slot:hover {
    fieldset {
      border: 2px solid var(--Gray-300, #525e77) !important;
    }
  }

  .v-list-item__content {
    padding: 0;

    .v-input__slot {
      margin-bottom: 0;
    }

    .v-text-field__details {
      display: none;
    }

    .v-text-field__slot {
      label {
      }
    }

    span.empty-info {
      color: var(--Gray-600, #525e77);
      font-family: Inter;
      font-size: 16px;
      font-style: italic;
      font-weight: 400;
      line-height: 140%;
      margin-bottom: 16px;
    }

    div.count-info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      color: var(--gfb-blue-brand-500, #0095e2);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;

      .clear-btn {
        font-weight: 600;
      }

      .count-display {
        display: flex;
        gap: 10px;
        align-items: center;

        .count {
          background: var(--gfb-blue-brand-500, #0095e2);
          border-radius: 50%;
          color: #fff;
          display: flex;
          width: 24px;
          height: 24px;
          padding: 0px 8px;
          justify-content: center;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }

  &:not(.v-input--is-dirty) {
    fieldset {
      max-height: 52px;
    }
  }
}
</style>
