<template>
  <div class="operational-grid-container">
    <div class="grid-item" v-for="(header, key) in headers" :key="key">
      <h4>
        <PhSquare color="#F77F00" weight="fill" size="12px"></PhSquare
        >{{ header }}
      </h4>
      <ul>
        <template v-if="key === 'industry'">
          <li v-for="(value, index) in profile[key]" :key="index">
            {{ value }}
          </li>
        </template>
        <li v-else>
          {{
            profile[key]
              ? showValue(profile[key])
              : showValue(profile.provider[key])
          }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { PhSquare } from "phosphor-vue"

export default {
  components: {
    PhSquare,
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    jobseekerCompanyView: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showValue(data) {
      let renderData = ""
      if (typeof data === "boolean") {
        renderData = data === true ? "Yes" : "No"
      } else {
        renderData = data
      }
      return renderData
    },
  },
  computed: {
    headers() {
      return this.jobseekerCompanyView
        ? {
            workRadius: "Work Radius",
            industry: "Industry of Work",
            numberOfEmployees: "Number of Employees",
          }
        : {
            workRadius: "Work Radius",
            industry: "Industry of Work",
            numberOfEmployees: "Number of Employees",
            volumeCompletedProjects: "Annual Volume of Projects",
            emr: "EMR",
            utilizeUnionLabor: "Utilizes Union Labor?",
            oshaCertification: "OSHA Certification",
            paymentProcessTime: "Payment Processing Time",
          }
    },
  },
}
</script>

<style lang="scss" scoped>
.operational-grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
}
.grid-item {
  h4 {
    border-bottom: 1px solid var(--Gray-200, #d5d9e2);
    padding: 8px 4px;
    font-size: 16px;
    font-weight: 600;
    width: fit-content;

    svg {
      margin-right: 8px;
    }
  }

  ul {
    list-style: none;
    padding: 0 4px;

    li {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .grid-item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 5px;
    margin-bottom: 10px;
  }

  .grid-item ul {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    align-items: end;
  }

  .grid-item h4 {
    border: unset;
    margin-bottom: 0;
  }

  .operational-grid-container {
    display: block;
  }

  .grid-item ul {
    list-style: none;
    padding: 0px;
  }

  .grid-item ul li {
    text-align: right;
  }
}
</style>
