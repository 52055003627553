import { render, staticRenderFns } from "./SearchProjects.vue?vue&type=template&id=ab995052&scoped=true"
import script from "./SearchProjects.vue?vue&type=script&lang=js"
export * from "./SearchProjects.vue?vue&type=script&lang=js"
import style1 from "./SearchProjects.vue?vue&type=style&index=1&id=ab995052&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab995052",
  null
  
)

export default component.exports