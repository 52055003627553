<template>
  <v-text-field
    v-model="value"
    :class="[classes, 'custom-text-field', { 'input-error': hasError }]"
    :label="label"
    :type="type"
    color="#0095E2"
    v-on="listeners"
    :hint="hasError ? errMsg : ''"
    :error="hasError"
    :error-messages="hasError ? errMsg : []"
    :disabled="disabled"
    outlined
  ></v-text-field>
</template>

<script>
export default {
  name: "text-input",
  props: {
    label: String,
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: [String, Number],
      description: "Input value",
    },
    classes: {
      type: String,
      description: "String classes to be applied to the root element",
      default() {
        return ""
      },
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errMsg: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur,
      }
    },
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value)
    },
    onFocus(value) {
      this.focused = true
      this.$emit("focus", value)
    },
    onBlur(value) {
      this.focused = false
      this.$emit("blur", value)
    },
  },
  data() {
    return {
      textField: null,
    }
  },
}
</script>

<style scoped></style>
