<!-- your-component.vue-->
<template>
  <div
    class="form-group"
    :class="[
      classes,
      'phone-input-wrapper',
      { 'has-danger': error },
      { focused: focused },
      { 'has-label': label || $slots.label },
    ]"
  >
    <label v-if="label" class="form-control-label">
      {{ label }}
      <span v-if="required">*</span>
    </label>
    <vue-phone-number-input
      :class="inputClasses"
      v-model="model"
      :required="required"
      valid-color="#525e77"
      :border-radius="borderRadius || 8"
      :error="!!error"
      :only-countries="['US']"
      default-country-code="US"
      :translations="translations"
      @phone-number-focused="handleFocus"
      @phone-number-blur="handleBlur"
      @input="handleInput"
      no-country-selector
      no-example
    />
    <div
      class="text-danger invalid-feedback"
      style="display: block"
      v-if="error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input"

export default {
  inheritAttrs: false,
  name: "phone-input",
  components: {
    VuePhoneNumberInput,
  },
  props: {
    classes: {
      type: String,
      description: "String classes to be applied to the root element",
      default() {
        return ""
      },
    },
    inputClasses: {
      type: String,
      description:
        "String classes to be applied to the vue-phone-number-input component",
      default() {
        return ""
      },
    },
    borderRadius: {
      type: Number,
      description:
        "Override for default vue-phone-number-input `border-radius` property",
    },
    required: {
      type: Boolean,
      description: "Whether input is required (adds an asterix *)",
    },
    label: {
      type: String,
      description: "Input label (text before input)",
    },
    error: {
      type: String,
      description: "Input error (below input)",
    },
    value: {
      type: String,
      description: "Input value",
    },
    isEditing: Boolean,
  },
  data() {
    return {
      focused: false,
      translations: {
        phoneNumberLabel: "Phone Number *",
      },
    }
  },
  methods: {
    handleFocus() {
      const elements = document.getElementsByClassName("input-phone-number")
      if (elements.length > 0) {
        const parent = elements[0]
        if (parent) {
          const input = parent.querySelector("input")
          setTimeout(() => {
            parent.classList.add("has-value")
            if (input) {
              input.placeholder = ""
            }
          }, 200)
        }
      }
    },
    handleBlur() {
      const elements = document.getElementsByClassName("input-phone-number")
      if (elements.length > 0) {
        const parent = elements[0]
        if (parent) {
          const input = parent.querySelector("input")
          if (input) {
            input.placeholder = "Phone Number *"
          }
        }
      }
    },
    handleInput() {
      const elements = document.getElementsByClassName("input-phone-number")
      if (elements.length > 0) {
        const parent = elements[0]
        if (parent) {
          const input = parent.querySelector("input")
          if (input) {
            input.placeholder = "Phone Number *"
          }
        }
      }
    },
  },
  computed: {
    model: {
      get() {
        return this.$props.value
      },
      set(value) {
        this.$emit("on-change", value)
      },
    },
  },
}
</script>

<style src="vue-phone-number-input/dist/vue-phone-number-input.css"></style>

<style lang="scss" global>
.vue-phone-number-input .input-tel.input-phone-number {
  height: 43px;
  min-height: 43px;
  & input {
    background-clip: padding-box;
    height: 50px;
    min-height: 50px;
    border-radius: 8px !important;
    border: 2px solid var(--Gray-300, #b0b9c9) !important;
    padding-top: 0 !important;
    font-size: 16px;
    font-weight: 400 !important;
    color: var(--Gray-900, #343946);

    &::placeholder {
      color: var(--Gray-600, #525e77) !important;
    }

    &:hover {
      border: 2px solid var(--Gray-500, #677690) !important;
    }

    &:focus {
      border: 2px solid var(--gfb-blue-brand-500, #0095e2) !important;
      box-shadow: unset !important;

      &::placeholder {
        color: var(--gfb-blue-brand-500, #0095e2) !important;
      }

      label {
        color: var(--gfb-blue-brand-500, #0095e2);

        &.text-danger {
          color: var(--Red-500, #e24646) !important;
        }
      }
    }
  }

  label {
    top: -9px;
    font-size: 12px !important;
    color: var(--Gray-600, #525e77) !important;
    background: #fff;
    padding: 0 5px;
  }

  &.has-error {
    & input {
      border: 2px solid var(--Red-500, #e24646) !important;

      &::placeholder {
        color: var(--Red-500, #e24646) !important;
      }
    }

    label {
      color: var(--Red-500, #e24646) !important;
    }
  }

  &.is-focused {
    label {
      color: var(--gfb-blue-brand-500, #0095e2) !important;

      &.text-danger {
        color: var(--Red-500, #e24646) !important;
      }
    }
  }
}

.invalid-feedback {
  color: #e24646 !important;
  font-size: 14px !important;
}

.phone-input-wrapper .invalid-feedback {
  margin-top: 15px;
}

.phone-input-wrapper {
  margin-bottom: 40px !important;
}
</style>
