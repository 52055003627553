<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar :background-color="sidebarBackground">
      <template slot="links">
        <v-navigation-drawer
          v-model="drawer"
          :mini-variant="mini"
          mobile-breakpoint="1024"
          permanent
        >
          <v-list :class="{ 'mini-list': mini }">
            <v-list-item
              title="Home"
              class="nav-link home-link mini-list-item"
              @click="$router.push('/dashboard')"
              link
            >
              <v-list-item-icon v-if="mini" class="icon-mini">
                <img class="nav-icon" :src="`/img/icons/gfb-logo-mini.svg`" />
                <span class="tooltipText">Home</span>
              </v-list-item-icon>
              <v-list-item-icon v-else class="icon-large">
                <img class="nav-icon" :src="`/img/icons/gfb-logo-large.svg`" />
              </v-list-item-icon>

              <v-list-item-content> </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-btn
            icon
            @click.stop="mini = !mini"
            :class="['toggle-nav', { open: mini }]"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>

          <v-list :class="{ 'mini-list': mini, 'nav-list': true }" dense>
            <v-list-item
              :title="item.title"
              v-for="item in sidebarItems"
              :key="item.title"
              class="nav-link"
              @click="$router.push(item.to)"
              link
            >
              <v-list-item-icon>
                <i :class="item.icon"></i>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                <div
                  v-if="
                    ((item.title == 'Find employees' ||
                      item.title == 'Search Jobs') &&
                      hasJobBriefingNotification) ||
                    (item.title == 'Find Partners' && hasProjectNotification) ||
                    (item.title == 'Find employees' &&
                      hasJobApplicationNotification)
                  "
                  style="
                    float: right;
                    position: relative;
                    top: -5px;
                    flex: unset;
                  "
                >
                  <svg
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="6.97656"
                      cy="6"
                      r="5"
                      fill="#FA0000"
                      stroke="white"
                      stroke-width="2"
                    />
                  </svg>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </template>
    </side-bar>

    <div class="main-content" :data="sidebarBackground">
      <AdminNavbar />
      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
    </div>
  </div>
</template>
<script>
import { FadeTransition } from "vue2-transitions"
import AdminNavbar from "./AdminNavbar.vue"

export default {
  components: {
    FadeTransition,
    AdminNavbar,
  },
  data() {
    const sidebarItems = [
      {
        title: "Dashboard",
        to: "/admin/dashboard",
        icon: "fas fa-home",
      },
      {
        title: "Companies",
        to: "/admin/companies",
        icon: "fas fa-handshake",
      },
      {
        title: "Job Seekers",
        to: "/admin/jobseekers",
        icon: "ni ni-single-02",
      },
    ]
    return {
      sidebarItems,
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary,
      drawer: true,
      mini: true,
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    },
  },
}
</script>
<style lang="scss">
.wrapper {
  display: flex;
}
</style>
