<template>
  <v-dialog v-model="show" persistent max-width="390">
    <v-card>
      <PhCircleNotch
        :size="100"
        weight="fill"
        color="#000"
        v-if="type == 'loading'"
        class="spin"
      />
      <PhCheck
        :size="100"
        weight="bold"
        color="#3D8116"
        v-if="type == 'success'"
      />
      <PhX :size="100" weight="bold" color="#F24423" v-if="type == 'fail'" />

      <v-card-title class="text-h5">
        {{ heading }}
      </v-card-title>
      <v-card-text>{{ description }}</v-card-text>
      <default-button
        :labelText="buttonLabel || 'Continue'"
        @click="handleContinue"
        v-if="type == 'success'"
      />
      <default-button
        :labelText="'Try Again'"
        @click="handleCancel"
        v-if="type == 'fail'"
      />
      <!-- <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="dialog = false"
            >
              Disagree
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="dialog = false"
            >
              Agree
            </v-btn>
          </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script>
import DefaultButton from "@/components/DefaultButton"
import { PhCircleNotch, PhCheck, PhX } from "phosphor-vue"
export default {
  name: "status-dialog",
  components: {
    DefaultButton,
    PhCircleNotch,
    PhCheck,
    PhX,
  },
  props: {
    heading: String,
    description: String,
    buttonLabel: String,
    type: { type: String, default: "loading" },
    show: { type: Boolean, default: false },
  },
  data: () => ({
    dialog: true,
  }),
  methods: {
    handleContinue() {
      this.$emit("continue")
    },
    handleCancel() {
      this.$emit("cancel")
    },
  },
}
</script>

<style scoped>
.spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.v-dialog {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.v-card {
  text-align: center;
  padding: 50px;
}

.v-card__title {
  justify-content: center;
  color: var(--Gray-800, #3a4252) !important;
  font-style: normal;
  font-weight: 600 !important;
}

.v-card__text {
  color: var(--Gray-800, #3a4252) !important;
  font-style: normal;
  font-weight: 400 !important;
}

.button-wrapper {
  input {
    width: 100%;
    font-weight: 600;
  }
}
</style>
